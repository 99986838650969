const AutonomousEnterpriseCard = ({ item, index }) => {
  return (
    <>
      <article
        className={`flex items-center gap-4 xs:gap-6 sm:gap-[42px] md:py-[30px] py-5 lg:px-5 px-3 h-full border-y border-dashed last:border-b-0 first:border-t-0 border-mist-border ${
          item.id === 3 || item.id === 4
            ? "md:border-y md:border-dashed md:border-mist-border"
            : "border-y md:border-y-0"
        } ${
          index % 2 === 0 ? "md:border-r border-dashed border-mist-border" : ""
        }`}
      >
        <span className="3xl:max-w-[72px] lg:max-w-[50px] max-w-10 3xl:max-h-[73px] lg:max-h-[50px] max-h-10 w-full h-full rounded-full">
          {item.icon}
        </span>
        <div>
          <h4 className="1xl:text-[28px] xl:text-2xl lg:text-xl text-lg font-black text-dark-knight">
            {item.title}
          </h4>
          <p className="mt-1 sub-heading">{item.descriptions}</p>
        </div>
      </article>
    </>
  );
};

export default AutonomousEnterpriseCard;

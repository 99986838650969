const PrimaryButton = ({ className, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
       className={`primary-button relative overflow-hidden group w-full  ${className}`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;

import React, { useEffect, useState, useCallback } from "react";
import scroll from "../../assets/images/svg/arrow-up-animated.svg";

const ScrollTop = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const checkScrollTop = useCallback(() => {
    setShowScrollTop(window.scrollY > 200);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [checkScrollTop]);

  const scrollTopHandler = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (!showScrollTop) return null;

  return (
    <button
      onClick={scrollTopHandler}
      className={`fixed lg:bottom-6 sm:bottom-5 bottom-3 z-50 lg:right-6 sm:right-5 right-3 bg-candy 3xl:w-[72px] 3xl:h-[72px] lg:w-14 lg:h-14 w-10 h-10 rounded-full bounce-button 3xl:p-3 p-1 transition-transform duration-300 ease-in-out translate-y-0 scale-100 flex items-center justify-center
      `}
      aria-label="Scroll to top"
    >
      <img className="max-w-9 w-full" src={scroll} alt="Scroll to top" />
    </button>
  );
};

export default ScrollTop;

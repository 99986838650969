const KeyBenefitsCard = ({ benefitsItem }) => {
  return (
    <>
      <article className="lg:px-[22px] p-4 md:py-8 py-6 flex items-center justify-center flex-col rounded-[28px] bg-veil hover:bg-frosted border-2 border-transparent hover:border-lilac duration-200 ease-in-out transition-all cursor-pointer shadow-c1 sm:w-[48%] w-full lg:w-fit hover:shadow-c1">
        <span className="3xl:max-w-[120px] 1xl:max-w-24 md:max-w-20 max-w-16 3xl:max-h-[120px] 1xl:max-h-24 md:max-h-20 max-h-16 w-full h-full rounded-full">
          {benefitsItem.icon}
        </span>
        <div className="max-w-[370px]">
          <h4 className="2xl:text-[32px] 1xl:text-3xl xl:text-2xl lg:text-xl !leading-[135%] text-lg font-black text-white my-4 text-center">
            {benefitsItem.title}
          </h4>
          <p className="sub-heading !text-rapunzel text-center !font-medium">
            {benefitsItem.descriptions}
          </p>
        </div>
      </article>
    </>
  );
};

export default KeyBenefitsCard;

const WhatMakesCard = ({ differentItem }) => {
  return (
    <>
      <article
        className={`lg:p-10 md:p-6 p-4 flex items-center justify-center flex-col ${differentItem.bgClass}`}
        key={differentItem.id}
      >
        <span className="3xl:max-w-[110px] 1xl:max-w-20 md:max-w-[60px] max-w-14  3xl:max-h-[110px] 1xl:max-h-20 md:max-h-[60px] max-h-14 w-full h-full rounded-full">
          {differentItem.icon}
        </span>
        <div>
          <h4 className="2xl:text-[32px] 1xl:text-3xl xl:text-2xl lg:text-xl text-lg font-bold text-secondary lg:mt-7 mt-5 lg:mb-5 mb-3 text-center">
            {differentItem.title}
          </h4>
          <p className="sub-heading !text-deep-night text-center font-normal 3xl:max-w-[376px] w-full mx-auto">
            {differentItem.descriptions}
          </p>
        </div>
      </article>
    </>
  );
};

export default WhatMakesCard;

// MobileSidebar.jsx
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import PrimaryButton from "./PrimaryButton";
import { CrossIcon, LogoIcon } from "./Icons";
import { useState } from "react";
import ContactUs from "./ContactUs";

const MobileSidebar = ({ isSidebarVisible, toggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { name: "Platform", path: "/" },
    { name: "About", path: "/about" }
  ];

  function handleopenContactUsModal() {
    setIsOpen(true);
  }

  function handlecloseContactUsModal() {
    setIsOpen(false);
  }

  return (
    <>
      {isSidebarVisible && (
        <div
          className="fixed inset-0 bg-black/70 z-40"
          onClick={toggleSidebar}
          aria-label="Close Sidebar"
        ></div>
      )}
      <aside
        className={`bg-[url('./assets/images/webp/hero-bg.webp')] object-cover bg-no-repeat w-full max-w-[276px] fixed lg:hidden flex flex-col justify-start items-start transition-transform duration-300 ease-in-out transform z-50 bottom-0 h-screen py-4  right-0 ${
          isSidebarVisible ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="h-full grow overflow-y-auto custom-scrollbar px-4 w-full">
          <div className="flex items-center justify-between gap-5">
            <Link
              className="w-full sm:mx-auto max-w-[130px] sm:max-w-fit"
              to={"/"}
            >
              <LogoIcon />
            </Link>
            <button
              className="border-primary border p-1.5 rounded-full hover:rotate-180 duration-200 ease-in-out transition-all"
              onClick={toggleSidebar}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="w-full">
            <ul className="flex flex-col items-center gap-3 mt-6">
              {navItems.map((item, index) => (
                <NavLinks item={item} key={index} onClick={toggleSidebar} />
              ))}
            </ul>
          </div>
        </div>
        <div className="px-6 w-full">
          <PrimaryButton
            onClick={handleopenContactUsModal}
            className={"w-full block xs:hidden"}
          >
            Get in touch
          </PrimaryButton>
        </div>
      </aside>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </>
  );
};

export default MobileSidebar;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import PrivacyPolicy from "./common/PrivacyPolicy";  // Ensure correct path
import Footer from "./common/Footer";
import AllRightReserved from "./common/AllRightReserved";
import UseTerms from "./common/UseTerms";
import Header from "./common/Header";
import Certification from "./common/Certification";
import About from "./common/about"; 




const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/Certification" exact element={<Certification />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" exact element={<UseTerms />} />
        <Route path="/compliance-security-quality" exact element={<Certification />} />
      </Routes>
      <Footer />
      <AllRightReserved />
    </BrowserRouter>
  );
};

export default AppRoutes;

import { capabilities } from "../common/Helper";
import CoreCapabilitiesCard from "./CoreCapabilitiesCard";

const CoreCapabilities = () => {
  const firstGroup = capabilities.slice(0, 3); // First 3 boxes
  const secondGroup = capabilities.slice(3, 5); // 4th and 5th boxes

  return (
    <section className="main-container 3xl:py-[120px] xl:py-20 lg:py-16 py-14">
      <h3 className="heading text-center text-black">Core Capabilities</h3>
      <div className="3xl:space-y-8 xl:space-y-6 space-y-4 lg:mt-[60px] md:mt-14 sm:mt-10 mt-6">
        <div className="grid md:grid-cols-3 xs:grid-cols-2 grid-cols-1 lg:gap-8 sm:gap-4 gap-3 bg-doctor rounded-[32px] py-3 1xl:py-6 3xl:py-10">
          {firstGroup.map((benefitsItem, index) => (
            <CoreCapabilitiesCard key={index} benefitsItem={benefitsItem} />
          ))}
        </div>
        <div className="flex justify-center items-center gap-[2px] bg-doctor rounded-[32px] py-3 1xl:py-6 3xl:py-10 flex-wrap gap-[16  px]">
  <div className="flex justify-center">
    <CoreCapabilitiesCard key={3} benefitsItem={secondGroup[0]} />
  </div>
  <CoreCapabilitiesCard key={4} benefitsItem={secondGroup[1]} />
</div>

      </div>
    </section>
  );
};

export default CoreCapabilities;

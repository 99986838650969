import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const appUrl = process.env.REACT_APP_NEW_BASE_URL;

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Actionabl</title>
        <meta
          name="description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
        <meta property="og:title" content="About Us | Actionabl" />
        <meta
          property="og:description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${appUrl}/og-img/Actionabl-About-Us.jpg`}
        />
        <meta
          name="twitter:image"
          content={`${appUrl}/og-img/Actionabl-About-Us.jpg`}
        />
        <meta name="twitter:title" content="About Us | Actionabl" />
        <meta
          name="twitter:description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
      </Helmet>

      <section className="bg-gray-100 min-h-screen font-satoshi">
        <div
          className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 mb-8 bg-cover bg-center"
          style={{
            backgroundImage: "url('/og-img/Actionabl-About-Us-Background.jpg')",
          }}
        >
          <h1 className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-left font-bold leading-tight pl-8 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            Driving Product Innovation & Fostering <br /> Impactful Customer
            Experiences
          </h1>
        </div>

        <div className="w-full px-4 sm:px-6 md:px-10 lg:px-12 py-6 md:py-8 lg:py-10 xl:py-12 text-gray-700 leading-relaxed mx-auto mb-8 shadow-lg rounded-lg">
          <div className="mt-4 pr-4 md:pr-[15px] pb-2">
            <Link to="/" className="text-blue-500 hover:underline">
              Home
            </Link>
            <span className="text-gray-600 mx-2"> &gt; </span>
            <span className="text-gray-800">About Us</span>
          </div>
          <p className="mb-4 text-lg text-deep-night">
            Actionabl began as more than a vision it was a shared mission to
            redefine how businesses leverage RPA, OCR, AI to streamline and
            automate complex processes, sparking a new era of product
            innovation. Founded by visionary technologists and business leaders,
            our journey is rooted in a commitment to empowering organizations in
            the digital age with transformative capabilities designed to deliver
            impactful customer experiences.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            The Spark of Transformation
          </h1>
          <p className="mb-4 text-lg text-deep-night">
            From the outset, our founders envisioned a new path for enterprise
            platforms, daring to ask: “What if work itself could be
            fundamentally transformed?” They envisioned not merely an evolution
            but a revolution in enterprise operations—a bold reimagining where
            work is seamlessly orchestrated and driven autonomously.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            The Origin of Actionabl: A Vision for Tomorrow’s Challenges
          </h1>
          <p className="mb-4 text-lg text-deep-night">
            The Actionabl Platform emerged as the answer to tomorrow's most
            pressing challenges. But this was not just another software update
            or technology integration. This was product innovation in its truest
            form—a futuristic platform of Business Automation with the vision of
            an Autonomous Enterprise at its core. Our guiding principle,
            "Shaping the Future of Autonomous Enterprise," has never been just a
            slogan; it’s the driving force behind a platform that redefines the
            way businesses operate and scale.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            Today and Beyond: Leading the Way to the Autonomous Enterprise
          </h1>
          <p className="mb-4 text-lg text-deep-night ">
            Today, Actionabl stands at the forefront of Business Orchestration
            and Automation, delivering next-gen automation solutions that shape
            the future of technology. Our commitment to continuous innovation
            and meaningful change ensures that we’re not just meeting today’s
            needs—we’re anticipating tomorrow’s challenges and opportunities. As
            we look to the future, our mission remains clear: to be the leader
            in Autonomous enterprise operations, enabling companies to operate
            smarter, more efficiently, and more agilely.
          </p>
        </div>
      </section>
      <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10 bg-white bg-opacity-50">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center mb-4">
          <span style={{ color: "#1E429F" }}>Leadership</span>
        </h2>

        <div className="grid grid-cols-1 gap-6 sm:gap-8 md:gap-10 mt-6 sm:mt-8 md:mt-10 mb-6">
          {/* Chiman Jagani */}
          <div className="grid grid-cols-1 gap-6 sm:gap-8 md:gap-10 mt-6 sm:mt-8 md:mt-10 mb-6">
            {/* First Card - Chiman Jagani */}
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a1.jpg`}
                  alt="Chiman Jagani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Chiman Jagani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Co-Founder
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  As an IIT Bombay alumnus, Chiman brings over 35 years of
                  extensive IT experience as a Technology & Innovation Lead. He
                  has delivered large-scale enterprise solutions for clients
                  across North America, Europe, and Asia. A visionary and
                  technology innovator, Chiman possesses a profound
                  understanding of the past and a forward-thinking perspective
                  for the future, making him a valuable asset in driving growth
                  and innovation.
                </p>
              </div>
            </div>

            {/* Second Card - Hiren Panchani */}
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:mr-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Hiren Panchani.jpg`}
                  alt="Hiren Panchani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Hiren Panchani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Business Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Hiren is a visionary leader with over 15 years of experience
                  in the digital transformation space, empowering businesses to
                  achieve full operational autonomy. As Chief Business Officer,
                  he will drive strategic planning, business operations,
                  financial management, culture, communications, partnerships,
                  collaboration, etc., and be responsible for Actionabl’s
                  business development & growth.
                </p>
              </div>
            </div>
          </div>

          {/* Rakesh Datta */}
          <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
            <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
              <img
                src={`${appUrl}/Developers/Rakesh Datta.jpg`}
                alt="Rakesh Datta"
                className="object-cover w-full h-full opacity-80"
              />
            </div>
            <div className="lg:w-auto">
              <h3
                className="text-lg sm:text-xl font-semibold"
                style={{ color: "#171616" }}
              >
                Rakesh Datta
              </h3>
              <p className="text-lg" style={{ color: "#1E429F" }}>
                Chief Sales Officer
              </p>
              <p className="text-gray-700 mt-2 text-sm sm:text-base">
                Rakesh Datta is a true sales leader with over 20 years of
                experience in driving business growth and strategic initiatives
                related to sales. He has worked with industry leaders such as
                Oracle, Jiffi.ai, etc. As Chief Sales Officer, Rakesh will
                execute strategic sales initiatives and lead the sales team,
                driving revenue growth.
              </p>
            </div>
          </div>

          {/* Anurag Saxena */}
          <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
            <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:mr-8 bg-red-200 flex items-center justify-center">
              <img
                src="/Developers/Anurag-Saxena.jpg"
                alt="Anurag Saxena"
                className="object-cover w-full h-full opacity-80"
                // style={{ width: "520px", height: "170px" }}
              />
            </div>
            <div className="lg:w-auto">
              <h3
                className="text-lg sm:text-xl font-semibold"
                style={{ color: "#171616" }}
              >
                Anurag Saxena
              </h3>
              <p className="text-lg" style={{ color: "#1E429F" }}>
                Chief Partnership Officer
              </p>
              <p className="text-gray-700 mt-2 text-sm sm:text-base">
                Anurag Saxena brings 27 years of experience with top
                organizations like Hitachi, Wipro, CoreStack, etc. As Chief
                Partnership Officer, he builds strategic partnerships and
                alliances to drive innovation and growth for Actionabl. Anurag’s
                expertise in relationship-building and channel sales helps align
                partnerships with company goals, unlocking new opportunities and
                strengthening market presence.
              </p>
            </div>
          </div>

          {/* Sarvesh Renghe */}
          <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
            <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
              <img
                src={`${appUrl}/Developers/a3.jpg`}
                alt="Sarvesh Renghe"
                className="object-cover w-full h-full opacity-80"
              />
            </div>
            <div className="lg:w-auto">
              <h3
                className="text-lg sm:text-xl font-semibold"
                style={{ color: "#171616" }}
              >
                Sarvesh Renghe
              </h3>
              <p className="text-lg" style={{ color: "#1E429F" }}>
                Chief Customer Success Officer
              </p>
              <p className="text-gray-700 mt-2 text-sm sm:text-base">
                Sarvesh Renghe is a highly experienced professional with over 30
                years in software development. As Chief Customer Success
                Officer, he leads the customer success function, ensuring
                clients achieve their goals and realize the full value of the
                company’s solutions. He excels in driving customer engagement,
                managing implementations, and building trusted partnerships.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

const CoreCapabilitiesCard = ({ benefitsItem }) => {
  if (!benefitsItem) {
    return null; // Don't render anything if benefitsItem is undefined
  }

  return (
    <div className="flex flex-wrap justify-center gap-6 custom-gap xl:pb-20 md:pb-16 pb-14">
    <article className="bg-white shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] hover:bg-[rgba(59,130,246,0.5)] hover:shadow-xl transition-shadow duration-300 lg:px-10 px-6 py-6 flex items-center justify-center flex-col rounded-[28px] cursor-pointer border border-gray-200 w-full sm:w-[300px] md:w-[400px] max-w-[90%] overflow-hidden">
      <span className="max-w-[70px] max-h-[70px] w-full h-full rounded-full flex items-center justify-center bg-gray-100 p-4">
        {benefitsItem.icon}
      </span>
      <p className="text-[14px] lg:text-2xl font-bold text-black-800 mt-4 text-center max-w-[600px]">
        {benefitsItem.descriptions}
      </p>
      <h4 className="text-[0.625rem] lg:text-[1.25rem] font-light text-black-1100 mt-2 text-center max-w-[600px]">
        {benefitsItem.title}
      </h4>
    </article>
  </div>

  
  );
};

export default CoreCapabilitiesCard;

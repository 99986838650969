import React, { useState } from "react";
import { HeroFutureIcon } from "../common/Icons";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";

// Securely fetch the app URL without using cookies
const appUrl = process.env.REACT_APP_NEW_BASE_URL;

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);

  const handleOpenVideo = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <section className="Shaping flex flex-col lg:flex-row items-center lg:items-start justify-center max-w-[1200px] w-full mx-auto py-20 lg:py-[140px] main-container hero-bg gap-10 mt-[-50px]">
      {/* Text Content Section */}
      <div className="flex-1 flex flex-col items-start justify-center gap-4">
        <h1 className="hero-heading text-white text-center lg:text-left">
          Shaping the
          <span className="relative mx-3">
            Future
            <HeroFutureIcon className="absolute -right-2 xl:-top-12 xs:-top-14 -top-[70px] z-[-1] pointer-events-none w-[140%] xs:w-[125%]" />
          </span>
          of Autonomous Enterprise
        </h1>

        <p className="hero-sub-heading md:mt-6 mt-3 lg:mb-[98px] sm:mb-16 mb-8 text-center lg:text-left">
          Elevate your Business to New Heights of Efficiency and Innovation.
        </p>

        <div
          className="btn flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-center gap-3 md:gap-4 lg:gap-6 mt-[20px] mb-[20px]"
          style={{ transform: "translateY(-6rem)" }}
        >
          <SecondaryButton className="btn max-w-[180px] w-auto py-0 3xl:text-xl hover:bg-blue-500 hover:text-white transition-all lg:relative lg:left-0 relative left-2.5">
            Schedule
          </SecondaryButton>
        </div>
      </div>

      {/* Video Thumbnail Section */}
      <div className="shaping-Img flex-1 flex items-center justify-center relative">
        <img
          src="/videoImg/Thumbnail.jpg"
          alt="Illustration"
          className="w-full max-w-[300px] lg:max-w-none object-contain"
        />

<div className="absolute inset-0 flex items-center justify-center">
    <a
      href="#"
      className="w-16 h-16 bg-purple-500 rounded-full ring-4 ring-white flex items-center justify-center hover:bg-purple-400 transition-transform transform hover:scale-110 shadow-lg"
      onClick={handleOpenVideo}
    >
      <span className="sr-only">Watch the video</span>
      <svg
        className="w-6 h-6"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
          fill="white"
        />
      </svg>
    </a>
  </div>


 
      </div>

      {/* Video Modal */}
      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-pink p-4 rounded-lg shadow-lg max-w-[800px] w-full">
            {/* Cancel Button */}
            <button
              onClick={handleCloseVideo}
              className="absolute top-2 right-2 bg-black text-white text-xl font-bold z-30 px-4 py-2 rounded-full shadow-md hover:bg-gray-800 focus:outline-none"
            >
              X
            </button>
            <div className="video-container relative">
			  <iframe
				src="https://www.youtube.com/embed/u8-hYTw0Ocw?autoplay=1&t=1s"
				title="YouTube video player"
				className="w-full h-[450px] rounded-lg relative z-10"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			  />
			</div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

import WhatMakesCard from "./WhatMakesCard";
import { different } from "../common/Helper";
const WhatMakes = () => {
  return (
    <section className="main-container bg-white 1xl:pb-[120px] xl:pb-20 md:pb-16 pb-14">
      <h3 className="heading text-center text-kuroi">
        What Makes Us Different
      </h3>
      <div className="grid md:grid-cols-2 grid-cols-1  h-full rounded-[32px] border border-smoke-gray overflow-hidden lg:mt-[60px] md:mt-14 mt-10">
        {different.map((differentItem, index) => (
          <WhatMakesCard key={index} differentItem={differentItem} />
        ))}
      </div>
    </section>
  );
};

export default WhatMakes;

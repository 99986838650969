const AllRightReserved = () => {
  return (
    <section className="border-t border-dark h-[61px] w-full flex items-center justify-center px-4 bg-reserved">
      <div className="flex items-center">
  <p className="mr-3 text-sm leading-[100%] text-light-gray">
    Copyright © 2024 Actionabl | All Rights Reserved. {new Date().getFullYear()}
  </p>
  <span className="flex border-l border-deep-night w-[2px] h-[22px]"></span>
  <p className="text-sm leading-[100%] text-light-gray pl-3">
    <a href="/terms-of-use" className="text-light-gray hover:underline">
      Terms of Use
    </a>
    {" | "}
    <a href="/privacy-policy" className="text-light-gray hover:underline">
      Privacy Policy
    </a>
  </p>
</div>
    </section>
  );
};

export default AllRightReserved;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Certification() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const apiUrl = process.env.REACT_APP_NEW_BASE_URL;

   
  return (
    <>
      <Helmet>
        <title>
          Certified Process Orchestration & Automation Platform | Actionabl
        </title>
        <meta
          name="description"
          content="Actionabl Platform provides top-tier security, quality, and data privacy. As an ISO 27001 certified, we ensure the highest standards of information security."
        />
        <meta
          property="og:title"
          content="Certified Process Orchestration & Automation Platform | Actionabl"
        />
        <meta
          property="og:description"
          content="Actionabl Platform provides top-tier security, quality, and data privacy. As an ISO 27001 certified, we ensure the highest standards of information security."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${apiUrl}/og-img/Actionabl-ISO-Og.jpg`}
        />
        <meta
          name="twitter:image"
          content={`${apiUrl}/og-img/Actionabl-ISO-Og.jpg`}
        />
        <meta
          name="twitter:title"
          content="Certified Process Orchestration & Automation Platform | Actionabl"
        />
        <meta
          name="twitter:description"
          content="Actionabl Platform provides top-tier security, quality, and data privacy. As an ISO 27001 certified, we ensure the highest standards of information security."
        />
      </Helmet>

      <div className="bg-gray-100 min-h-screen font-satoshi">
        <div
          className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 mb-8 bg-cover bg-center"
          style={{
            backgroundImage:
              "url('/og-img/Background-Img/Actionabl-ISO-background.jpg')",
          }}
        >
          <h1 className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-left font-bold leading-tight pl-8 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            From Standards to Security: <br /> Actionable's Quality Assurance
            Framework
          </h1>
        </div>

        <div className="w-full px-4 sm:px-6 md:px-10 lg:px-12 py-6 md:py-8 lg:py-10 xl:py-12 text-gray-700 leading-relaxed mx-auto mb-8 shadow-lg rounded-lg">
          <div className="mt-4 pr-4 md:pr-[15px]">
            <Link to="/" className="text-blue-500 hover:underline">
              Home
            </Link>
            <span className="text-gray-600 mx-2"> &gt; </span>
            <span className="text-gray-800">compliance security quality</span>
          </div>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            Actionabl is ISO 27001 Certified
          </h1>

          <p className="mb-4 text-lg text-deep-night ">
            Protecting your intellectual property and data is at the core of
            everything we do. At Actionabl, we’ve integrated security and
            compliance directly into the foundation of our end-to-end Business
            Process Orchestration & Automation platform, ensuring seamless
            protection across every workflow.
          </p>

          <p className="mb-4 text-lg text-deep-night">
            The Actionabl Platform is designed with best-in-class security and
            data privacy features, so you can operate with full confidence,
            knowing your critical assets are protected. Our ISO 27001
            certification underscores our unwavering dedication to safeguarding
            your business, making security and compliance effortless as you
            scale and grow.
          </p>
        </div>

        <div className="bg-[#7B5BF0] md:bg-[#7B5BF0] text-white mb-[90px] mt-[90px]">
          <div className="flex flex-col md:flex-row items-start p-4 sm:p-6 md:p-8 lg:p-10 border border-gray-300 rounded-lg space-y-5 md:space-y-0 md:space-x-6">
            <img
              src="/Certified27001/Certified27001.png"
              alt="ISO Certification"
              className="w-full md:w-1/3 "
            />
            <div className="md:w-2/3">
              <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-4 pt-4 sm:pt-6 md:pt-10 lg:pt-12">
                ISO Certification
              </h1>
              <p className="mb-4 text-lg sm:text-base leading-relaxed">
                ISO 27001, a globally recognized standard, plays a crucial role
                in safeguarding information security by mitigating risks across
                industries and regions. This certification mandates the
                development of a comprehensive Information Security Management
                System (ISMS), ensuring that robust security controls are
                implemented across management, operational, and technical
                levels.
              </p>
              <p className="text-lg sm:text-base leading-relaxed">
                At Actionabl, we take pride in being ISO 27001 certified,
                reflecting our unwavering dedication to advanced security
                readiness. Our ISMS framework is designed not only to meet but
                exceed the highest security standards. With rigorous controls in
                place, we proactively manage and mitigate risks, guaranteeing
                that your sensitive data remains protected, and instilling trust
                and confidence in our security practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Certification;

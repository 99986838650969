import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import PrimaryButton from "./PrimaryButton";
import { MenuIcon } from "./Icons";
import NavLinks from "./NavLinks";
import { LogoIcon } from "./Icons";
import MobileSidebar from "./MobileSidebar";
import ContactUs from "./ContactUs";

const Header = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleopenContactUsModal = () => {
    setIsOpen(true);
  };

  const handlecloseContactUsModal = () => {
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    document.body.style.overflow = isSidebarVisible ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarVisible]);

  const navItems = [ 
    { name: "Platform", path: "/" },
    { name: "About", path: "/about" },
    // { name: "Services", path: "/services" },
    // { name: "Privacy Policy", path: "/privacy-policy" },
    // { name: "Contact", path: "/contact" },
  ];

  let location = useLocation();
  const url_pathname = location.pathname;
  const ignorePaths = ["/"];

  useEffect(() => {
    const heroBgElement = document.getElementById("header-bg");

    if (heroBgElement) {
      if (!ignorePaths.includes(url_pathname)) {
        heroBgElement.style.backgroundColor = "#00004B";
        heroBgElement.style.backgroundImage = "none";
        heroBgElement.classList.remove("hero-bg");
      } else {
        heroBgElement.style.backgroundColor = "transparent";
        heroBgElement.style.backgroundImage = "none";
        heroBgElement.classList.add("hero-bg");
      }
    }
  }, [url_pathname]);

  return (
    <>
      <header className="relative w-full hero-bg" id="header-bg">
        <div className="relative z-40 3xl:h-[96px] md:h-[75px] h-14 flex items-center">
          <div className="flex justify-between items-center gap-4 main-container">
            <Link
              to={"/"}
              className="md:max-w-[215px] sm:max-w-[180px] max-w-[130px] w-full"
            >
              <LogoIcon />
            </Link>
            <div className="flex justify-between items-center gap-14">
              <ul className="hidden md:flex items-center gap-8 pr-[97px]">
                {navItems.map((item, index) => (
                  <NavLinks item={item} key={index} />
                ))}
              </ul>
            </div>
            <div className="flex items-center gap-2">
              <PrimaryButton
                onClick={handleopenContactUsModal}
                className="max-w-[180px] w-full xs:block hidden"
              >
                Contact Us
              </PrimaryButton>
              <button onClick={toggleSidebar} className="block md:hidden">
                <MenuIcon />
              </button>
            </div>
          </div>
        </div>
        <MobileSidebar
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      </header>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </>
  );
};

export default Header;

// NavLinks.jsx
import { Link, useLocation } from "react-router-dom";

const NavLinks = ({ item, onClick }) => { const location = useLocation();
  const active = location.pathname === item.path; 
  return (
    <li className="relative group">
      <Link
        onClick={onClick}
        to={item.path}
        className={`block py-2 md:text-lg text-base ${
          active ? "text-baby font-bold" : "text-white font-medium"
        } hover:text-baby transition-colors duration-300`}
      >
        {item.name}
        <span
          className={`absolute left-1/2 transform -translate-x-1/2 h-0.5 bg-baby 
            transition-all duration-300 rounded-full ${
              active ? "w-1/2 bottom-0" : "w-0 bottom-0 group-hover:w-full"
            }`}
        ></span>
      </Link>
    </li>
  );
};

export default NavLinks;

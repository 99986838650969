import React from 'react';

const Ecosystem = () => {
  return (
    <div className="bg-white py-12">
  <div className="text-center">
    <h1
      className="heading text-black"
      style={{ paddingTop: "10px", paddingBottom: "40px" }}
    >
      Actionable Ecosystem
    </h1>
  </div>

  <div className="flex flex-col items-center gap-8 md:flex-row md:justify-center md:gap-8 lg:gap-8">
    <img
      src="/client/HDFC.png"
      style={{ paddingLeft: "23px",paddingBottom:"8px" }}
      alt="HDFC"
      className="h-16 pt-2 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90"
    />
    <img
      src="/client/Kotak Group.jpg"
      alt="Kotak Group"
      className="h-14 pr-5 ml-7 sm:ml-8 sm:pr-6 md:ml-10 md:pr-8 lg:ml-12 lg:pr-10 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90"
    />
    <img
      src="/client/lulu-financial-holdings.png"
      alt="Lulu Group"
      className="h-28 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90 pr-6"
    />
    <img
      src="/client/featsystems-logo.svg"
      height="56"
      width="226"
      alt="featsystems"
      className="h-22 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90"
    />
  </div>
</div>

  );
};

export default Ecosystem;

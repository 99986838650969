import "./App.css";
import "./index.css";
import AppRoutes from "./Components/AppRoutes.js";

function App() {
  return (
    <>
      <AppRoutes />
   
    </>
  );
}

export default App;
